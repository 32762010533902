// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const initPage = function () {
  const topButton = document.querySelector('#top-button');
  if (topButton) {
    topButton.onclick = function () {
      window.scrollTo(0, 0);
    };
    const top = topButton.offsetTop + topButton.offsetParent.offsetTop;
    window.onscroll = function () {
      if (window.pageYOffset - top < 0) {
        topButton.classList.add('position-absolute');
        topButton.classList.remove('position-fixed');
      } else {
        topButton.classList.remove('position-absolute');
        topButton.classList.add('position-fixed');
      }
    };
  }
  const form = document.getElementById("newletter-form");
  if (form) {
    form.addEventListener("submit", (event) => {
      event.preventDefault();
      const recaptcha = $("#g-recaptcha-response").val();
      if (recaptcha === "") {
        setTimeout(() => {
          document.getElementById("submit-button").removeAttribute("disabled");
          document.getElementById("error-message").classList.remove("d-none");
        }, 500);
      } else {
        const submitMessage = document.getElementById("submit-message");
        const loadingMessage = document.getElementById("loading-message");
        const errorMessage = document.getElementById("error-message");
        form.classList.add('d-none');
        loadingMessage.classList.remove('d-none');
        const data = new FormData(event.target);
        const config = {
          method: "POST",
          mode: "no-cors",
          body: data,
        };
        fetch('https://joynup.activehosted.com/proc.php', config)
          .then(data => {
            if (data.status === "error") throw new Error(data.message);
            submitMessage.innerHTML = '✅  Thank you for joining our Newsletter!';
          })
          .catch(e => errorMessage.classList.remove('d-none'))
          .finally(() => {
            loadingMessage.classList.add('d-none');
            submitMessage.classList.remove('d-none');
          });
      }  
    });
  }
};

window.addEventListener('turbolinks:load', (e) => {
  initPage();
});
